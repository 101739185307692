@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import 'styles/sass/utilities/variables';
@import 'styles/sass/utilities/functions';
@import 'styles/sass/utilities/mixins';

html,
body {
  height: 100%;
  margin: 0;
}
body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  padding: 0;
  padding-top: env(safe-area-inset-top);
  font-family: $open-sans;
}

// Global Checkbox & Radio Button Style Starts //
.custom-checkbox input[type='checkbox'],
.custom-radio input[type='radio'] {
  display: none;
}

.custom-checkbox,
.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accountpopup-modal {
  --bs-modal-footer-border-color: transparent;
  border: none;
}
.checkbox-indicator,
.radio-indicator {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
  background-color: #fff;
  transition:
    background-color 0.3s,
    border-color 0.3s;
}

.checkbox-image,
.radio-image {
  display: none;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 4px;
  top: 4px;
  pointer-events: none;
}
.dashboard-spinner {
  .spinner {
    box-shadow:
      14px 0px 0 1px #003595,
      10px 10px 0 1.1px #003595,
      0px 14px 0 1.2px #003595,
      -10px 10px 0 1.3px #003595,
      -14px 0px 0 1.5px #003595,
      -10px -10px 0 1.8px #003595,
      0px -14px 0 2px #003595,
      10px -10px 0 1px #003595 !important;
  }
}
.custom-checkbox input[type='checkbox']:checked + .checkbox-indicator,
.custom-checkbox input[type='checkbox']:disabled + .checkbox-indicator,
.custom-radio input[type='radio']:checked + .radio-indicator,
.custom-radio input[type='radio'] + .radio-indicator {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-checkbox input[type='checkbox']:checked + .checkbox-indicator {
  background-image: url(./assets/images/checkbox-enabled.png);
}

.custom-checkbox input[type='checkbox'] + .checkbox-indicator {
  border-radius: 4px;
  border: 1px solid $ace-neutral-500;
  background: $primary-white;
}

.custom-checkbox input[type='checkbox']:disabled:checked + .checkbox-indicator {
  background-image: url(./assets/images/checkbox-disabled.png);
  border: 0;
}

.custom-checkbox input[type='checkbox']:disabled + .checkbox-indicator,
.custom-radio input[type='radio']:disabled + .radio-indicator {
  cursor: not-allowed;
}

.custom-radio input[type='radio']:checked + .radio-indicator {
  background-image: url(./assets/images/radio-enabled.png);
  width: 20px;
  height: 20px;
}

.custom-radio input[type='radio'] + .radio-indicator {
  background-image: url(./assets/images/radio-disabled.png);
  width: 20px;
  height: 20px;
}

.custom-checkbox
  input[type='checkbox']:checked
  + .checkbox-indicator
  + .checkbox-image,
.custom-radio input[type='radio']:checked + .radio-indicator + .radio-image {
  display: block;
}
// Global Checkbox & Radio Button Style Ends //

// ---------------------------------------Global Ng Select Styling Starts---------------------------------------
.ng-select.ng-select-single .ng-select-container,
.ng-select.ng-select-multiple .ng-select-container {
  height: 37px;
  border-color: $ace-neutral-500;
  border-radius: 24px;
  padding: 6px 0;
}

.ng-select .ng-arrow-wrapper {
  background-image: url('./assets/images/dropdown-down-arrow.svg');
  margin-top: 6px;
  margin-right: 8px;
}

.ng-select.ng-select-multiple .ng-arrow-wrapper {
  margin-right: 16px;
}

.ng-select.ng-select-opened .ng-arrow-wrapper {
  background-image: url('./assets/images/dropdown-up-arrow.svg');
  margin-top: 6px;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border: none;
}

//single select dropdown styles
.singleSelect-usermanagement-dropdown
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container {
  @include media-breakpoint-down(sm) {
    display: flex;
    flex: 1;
    padding: 8px 16px;
    gap: 8px;
  }
}

.facilities-management
  .ng-select.ng-select-single
  .ng-select-container
  .ng-value-container {
  padding-left: 15px;
}

.facilities-management-error {
  .ng-select.ng-select-single .ng-select-container {
    border-color: $ace-alert-500;

    .ng-placeholder {
      @include media-breakpoint-down(msm) {
        width: 85%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.search-filter-container {
  width: 380px;
  height: 36px;
  padding: 32px 0px 40px 0px;
  margin: auto;
  gap: 8px;
  input {
    width: 380px !important;
    &:focus {
      box-shadow: 0px 0px 0px 3px #00aeef66 !important;
      border: 1px solid $primary-blue !important;
    }
    @include media-breakpoint-down(sm) {
      width: 343px !important;
      margin: 0 16px;
    }
  }

  @include media-breakpoint-down(sm) {
    .search__container__line {
      right: 46px !important;
    }
    .search-icon {
      right: 28px !important;
    }
    .clear-icon {
      right: 56px !important;
    }
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  min-width: 48px;
  margin-right: 8px;
  @include fontStyle($open-sans, $font-normal, 400, 14px, $ace-neutral-500);
}

.accessTypeDropdown {
  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-value {
    color: $primary-black;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 8px;
  border-radius: 24px;
}
.ng-dropdown-panel.ng-select-top {
  margin-bottom: 8px;
  border-radius: 24px;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-radius: 24px;
}
.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-radius: 24px;
}
.ng-dropdown-panel .scroll-host {
  border-radius: 24px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 12px 16px;
  @include fontStyle($open-sans, $font-normal, 400, 14px, $ace-neutral-800);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  color: unset;
  background-color: unset;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: unset;
  background-color: unset;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: unset;
}
.ng-dropdown-panel .ng-dropdown-footer {
  padding: unset;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  margin-bottom: unset;
  background-color: unset;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: unset;
  padding-bottom: unset;
  @include fontStyle($open-sans, $font-normal, 600, 14px, $ace-neutral-700);
}

.multiselectLabel {
  border: 1px solid #66cef5;
  padding: 4px 8px;
  border-radius: 14px;
  background: #e5f7fd;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 12px;

  .closeIcon {
    display: flex;
    cursor: pointer;
  }
}

.multiselectLabelAllAccounts {
  margin-left: 13px;
  color: var(
    --Neutral-700,
    var(--Neutral-ace-color-neutral-700, $ace-neutral-700)
  );
  font-size: var(--Font-Size-S, 16px);
  font-weight: 400;
}
/* Global Ng Select Styling Ends */

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: unset;
  padding: 12px 16px;
}
.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 265px !important;
}
.search-container {
  .ng-select {
    width: 126px;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  input {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-value {
    color: $ace-neutral-800;
  }

  .ng-select.ng-select-single .ng-select-container,
  .ng-select.ng-select-multiple .ng-select-container {
    padding: 8px 16px;
    gap: 8px;
  }

  .ng-select .ng-select-container .ng-value-container {
    padding: 0;
  }
  .ng-select .ng-arrow-wrapper {
    margin-right: 0;
  }
}
.custom-dropdown-checkbox {
  appearance: none;
  width: 20px; /* Set size */
  height: 20px; /* Set size */
  border: 1px solid $ace-neutral-500; /* Border color */
  border-radius: 4px; /* Optional: Rounded corners */
  outline: none;
  cursor: pointer;
  background-color: $primary-white; /* Default background */
}
.custom-dropdown-checkbox:checked {
  background-color: $primary-blue; /* Checkbox fill color */
  border-color: $primary-blue; /* Change border color when checked */
  position: relative;
}

/* Add checkmark */
.custom-dropdown-checkbox:checked::after {
  content: '';
  display: block;
  width: 6px;
  height: 12px;
  border: solid $primary-white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 7px;
}
.multi-select-dropdown-item {
  .ng-select-dropdownItem-mainValue {
    @include fontStyle(
      'Open Sans',
      normal,
      400,
      14px,
      var(--ace-color-neutral-800, $ace-neutral-700)
    );
    line-height: 140%;
  }

  .ng-select-dropdownItem-subValue {
    @include fontStyle(
      'Open Sans',
      normal,
      400,
      12px,
      var(--ace-color-depricated-neutral-500, $ace-neutral-500)
    );
    line-height: 140%;
  }

  .selected {
    @include fontStyle(
      'Open Sans',
      normal,
      600,
      14px,
      var(--ace-color-neutral-800, $ace-neutral-700)
    );
    line-height: 140%;
  }
}

.userCreatedPopup .userCreatedPopupLink a {
  border-bottom: 2px solid #003595;
  text-decoration: none;
  @include fontStyle($open-sans, $font-normal, 600, 16px, $primary-blue);
}
//----------------------------------------xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx---------------------------------------
